import React from 'react';
import type {
    SearchSuggestion,
    SearchSuggestionGroup,
} from '@rocco/ui/search/view-models/search-suggestion';
import { SearchSuggestionItem } from '@rocco/ui/search/components/SearchSuggestionItem';

interface SearchSuggestionListProps {
    group: SearchSuggestionGroup;
    query: string;
    onOptionClick: (suggestion: SearchSuggestion | string) => void;
}

const SUGGESTION_TYPE_TITLES = {
    keyword: 'Suggestions',
    categories: 'Categories',
} as const;

export const SearchSuggestionList: React.FC<SearchSuggestionListProps> = ({
    group,
    query,
    onOptionClick,
}) => {
    return (
        <div>
            <h3 className="md:mb-4 text-base font-medium text-gray-800">
                {SUGGESTION_TYPE_TITLES[group.type] || ''}
            </h3>
            <div className="my-4.5 space-y-2.5 md:my-0 md:space-y-0">
                {group.options.map((option, optionIndex) => (
                    <SearchSuggestionItem
                        key={optionIndex}
                        label={
                            typeof option === 'string' ? option : option.title
                        }
                        type={
                            typeof option === 'string' ? undefined : option.type
                        }
                        query={query}
                        onClick={() => onOptionClick(option)}
                    />
                ))}
            </div>
        </div>
    );
};
