import type {
    PathUrlParamConfiguration,
    UrlConfiguration,
    UrlParamConfiguration,
} from '@modules-rocco/url/config/url.config';
import { resolveDynamicBoolean } from '@modules-rocco/url/config/url.config';
import {
    isCustomPathUrlParamConfiguration,
    isPathUrlParamConfiguration,
    urlConfig,
} from '@modules-rocco/url/config/url.config';
import type {
    URLInfo,
    URLParams,
    URLParamValue,
} from '@modules-rocco/url/types';
import { decodePathValue } from '@modules-rocco/url/api/decode-path-value';
import type { FeatureFlags } from '@modules/root';

interface RawUrlParam {
    asPath: boolean;
    values: string[];
}

interface MappedPathParamConfig {
    realKey: string;
    config: PathUrlParamConfiguration;
}

/**
 * Parses a URL string into base path and parameter information.
 *
 * This function takes a URL string and configuration, and breaks it down into:
 * 1. Base path - The main URL path without parameters
 * 2. URL parameters - Both query string and path parameters with their values
 *
 * The function handles:
 * - Query string parameters (e.g. ?search=kitchen&page=2)
 * - Path parameters (e.g. /materials-wood)
 * - Parameter type conversion based on configuration (boolean, string, number etc)
 * - Multiple values for parameters that support it
 * - Encoded parameter values
 *
 * @param url - The URL string to parse (e.g. "/products/materials-wood?page=2")
 * @param featureSet - The set of features to include in the parsing process.
 * @param config - Configuration object defining parameter types and path ordering. Defaults to urlConfig.
 * @returns URLInfo object containing:
 *          - basePath: The base URL path without parameters (includes path parameters if included in the url)
 *          - params: Object containing all parsed parameters with their values and metadata
 */
export const parseURL = (
    url: string,
    featureSet: FeatureFlags = [],
    config: UrlConfiguration = urlConfig
): URLInfo => {
    const startWithSlash = url.startsWith('/');
    const [allPath, searchParamsStr] = decodeURI(url).split('?');

    if (!allPath) {
        return {
            basePath: url,
            params: {},
        };
    }

    // process url config
    const urlParamsConfig = config.params;
    const pathParamConfigs = Object.fromEntries(
        Object.entries(urlParamsConfig)
            .filter(
                ([, urlParamConfig]) =>
                    isPathUrlParamConfiguration(urlParamConfig) &&
                    resolveDynamicBoolean(urlParamConfig.asPath, featureSet)
            )
            .map(([key, urlParamConfig]) => [
                isCustomPathUrlParamConfiguration(urlParamConfig)
                    ? urlParamConfig.customPath
                    : key,
                {
                    realKey: key,
                    config: urlParamConfig,
                },
            ])
    ) as Record<string, MappedPathParamConfig>;

    // collect parameters
    let rawParams: Record<string, RawUrlParam> = {};

    // get all search parameters
    // for now we just save them to params for future processing
    const searchParams = new URLSearchParams(searchParamsStr);

    for (const key of searchParams.keys()) {
        const values = searchParams.getAll(key);

        if (values.length) {
            rawParams[key] = {
                asPath: false,
                values,
            };
        }
    }

    // get all path parameters (+ collect base path parts)
    // for now we just save them to params for future processing
    // path parameters will take over search parameters if an overlap is found
    const paths = allPath.split('/').filter((p) => !!p);
    const basePaths: string[] = [];

    let foundPathParams = false;

    paths.forEach((path) => {
        const pathParam = parsePathParameter(path, pathParamConfigs);

        if (pathParam) {
            foundPathParams = true;
            // override
            rawParams = { ...rawParams, ...pathParam };
        } else {
            if (!foundPathParams) basePaths.push(path);
        }
    });

    // build base path
    const basePath = `${startWithSlash ? '/' : ''}${basePaths.join('/')}`;

    // parse parameters based on type
    const params: URLParams = Object.entries(rawParams).reduce(
        (acc, [key, rawParam]) => {
            const urlParamConfig = urlParamsConfig[key];

            if (urlParamConfig) {
                const value = getProcessedValue(
                    rawParam.values,
                    urlParamConfig
                );

                if (value !== undefined) {
                    return {
                        ...acc,
                        [key]: {
                            asPath: rawParam.asPath,
                            value,
                            raw: rawParam.values,
                            configured: true,
                        },
                    };
                }
            } else {
                return {
                    ...acc,
                    [key]: {
                        asPath: false,
                        value: rawParam.values,
                        raw: rawParam.values,
                        configured: false,
                    },
                };
            }

            return acc;
        },
        {} as URLParams
    );

    return { basePath, params };
};

/**
 * Parses a path parameter and returns a key-value pair if a valid path parameter is found.
 *
 * @param path - The path string to parse
 * @param mappedPathParamConfigs - A record of path parameter configurations
 * @returns An object with the parsed path parameter key and its value, or undefined if no valid parameter is found
 */
const parsePathParameter = (
    path: string,
    mappedPathParamConfigs: Record<string, MappedPathParamConfig>
): Record<string, RawUrlParam> | undefined => {
    // look for custom path params first
    // it works for boolean params only
    const customPathConfig = Object.values(mappedPathParamConfigs).find(
        (c) =>
            isCustomPathUrlParamConfiguration(c.config) &&
            c.config.type === 'boolean' &&
            path === c.config.customPath
    );

    if (customPathConfig) {
        return {
            [customPathConfig.realKey]: {
                asPath: true,
                values: ['1'],
            },
        };
    }

    const config = Object.entries(mappedPathParamConfigs).find(([k, c]) =>
        path.startsWith(k + (c.config.separator ?? '-'))
    )?.[1]?.config;

    if (!config) {
        return undefined;
    }

    // if no custom path param found, look for standard path params
    // standard path params are separated by '-' by default (or the separator from the config)
    const separator = config.separator ?? '-';

    if (separator.includes('/')) {
        throw new Error('Separator cannot contain forward slashes');
    }

    const [key, value] = path.split(separator, 2);

    if (!key || !value || !Object.keys(mappedPathParamConfigs).includes(key)) {
        return undefined;
    }

    return {
        [key]: {
            asPath: true,
            values: [value],
        },
    };
};

/**
 * Processes a raw parameter value based on its configuration and returns a processed value.
 *
 * @param value - The raw parameter value to process
 * @param urlParamConfig - The configuration for the parameter
 * @returns The processed value of the parameter, or undefined if processing fails
 */
const getProcessedValue = (
    value: string | string[],
    urlParamConfig: UrlParamConfiguration
): URLParamValue | undefined => {
    if (urlParamConfig.type === 'boolean') {
        return getBoolean(value);
    }

    if (
        urlParamConfig.type === 'string' ||
        urlParamConfig.type === 'searchable'
    ) {
        return urlParamConfig.multiple
            ? getStringArray(value)
            : getString(value);
    }

    if (urlParamConfig.type === 'number') {
        return urlParamConfig.multiple
            ? getNumberArray(value)
            : getNumber(value);
    }

    if (urlParamConfig.type === 'range') {
        return getNumberRange(value);
    }

    return value;
};

export const getBoolean = (value: string | string[]): boolean => {
    if (!value || (Array.isArray(value) && !value.length)) {
        return false;
    }

    return (Array.isArray(value) ? value[0] : value) === '1';
};

export const getString = (
    value: string | string[],
    decode: boolean = false
): string | undefined => {
    if (!value || (Array.isArray(value) && !value.length)) {
        return undefined;
    }

    const castedValue = Array.isArray(value)
        ? value[0]?.toString()
        : value.toString();

    return decode ? decodePathValue(castedValue) : castedValue;
};

export const getStringArray = (
    value: string | string[],
    decode: boolean = false
): string[] | undefined => {
    if (!value || (Array.isArray(value) && !value.length)) {
        return undefined;
    }

    const values = Array.isArray(value)
        ? value.map((v) =>
              decode ? decodePathValue(v.toString()) : v.toString()
          )
        : [decode ? decodePathValue(value.toString()) : value.toString()];

    const resultValues = values.filter((v): v is string => v !== undefined);

    return resultValues.length > 0 ? resultValues : undefined;
};

export const getNumber = (value: string | string[]): number | undefined => {
    const singleValue = Array.isArray(value) ? value[0] : value;

    if (isNaN(Number(singleValue))) {
        return undefined;
    }

    return Number(value);
};

export const getNumberArray = (
    value: string | string[]
): number[] | undefined => {
    if (!value || (Array.isArray(value) && !value.length)) {
        return undefined;
    }

    const numbers = Array.isArray(value)
        ? value.map(getNumber)
        : [getNumber(value)];
    const filtered = numbers.filter((n): n is number => n !== undefined);

    return filtered.length > 0 ? filtered : undefined;
};

export const getNumberRange = (
    value: string | string[],
    multiplier = 1
): [number, number] | undefined => {
    const singleValue = Array.isArray(value) ? value[0] : value;

    if (typeof singleValue !== 'string') {
        return undefined;
    }

    const rangeStrings = singleValue.split(',');
    if (rangeStrings.length !== 2) {
        return undefined;
    }

    if (rangeStrings.some((str) => isNaN(parseFloat(str)))) {
        return undefined;
    }

    const range = rangeStrings.map((value) => parseFloat(value) * multiplier);

    return range.sort((a, b) => a - b) as [number, number];
};
