import React from 'react';

interface SearchRecentlySearchedPopupProps {
    recentlySearched: string[];
    clearHistory: () => void;
    onItemClick: (search: string) => void;
}

export const SearchRecentlySearchedPopup: React.FC<
    SearchRecentlySearchedPopupProps
> = ({ recentlySearched, onItemClick, clearHistory }) => {
    return (
        <div
            className="absolute top-full z-popover mt-4 w-full rounded-xl border border-gray-300 bg-white p-8 pt-9 shadow-lg"
            id="search-autocomplete"
            aria-haspopup="listbox"
        >
            <div>
                <h3 className="md:mb-4 text-base font-medium text-gray-800">
                    Recently Searched
                </h3>
                <div className="my-4.5 space-y-2.5 md:my-0 md:space-y-0">
                    {recentlySearched.map(searchItem => (
                        <div
                            key={searchItem}
                            onClick={() => onItemClick(searchItem)}
                            className="flex cursor-pointer items-center md:p-2 md:hover:bg-gray-100 md:focus:bg-gray-100 focus:outline-none"
                            role="option"
                            tabIndex={0}
                            aria-selected={false}
                        >
                            <div className="min-h-4.5 leading-[1.1] md:min-h-0 text-[#9b9b9b] md:text-gray-400 flex items-center whitespace-nowrap max-w-full gap-1">
                                <span>{searchItem}</span>
                            </div>
                        </div>
                    ))}
                    <div
                        onClick={clearHistory}
                        className="flex cursor-pointer items-center md:p-2 md:hover:bg-gray-100 md:focus:bg-gray-100 focus:outline-none"
                        role="option"
                        tabIndex={1}
                        aria-selected={false}
                    >
                        <div className="min-h-4.5 leading-[1.1] md:min-h-0 text-[#9b9b9b] md:text-gray-400 flex items-center whitespace-nowrap max-w-full gap-1">
                            <span className="text-gray-900 font-medium">
                                {`Clear History`}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
