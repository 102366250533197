import { useCallback } from 'react';
import { useTracker } from '@archipro-website/tracker';
import { useSharedTiles } from '@rocco/ui/tile-shared/hooks/use-shared-tiles';
import { getSourceSection } from '~/utils/get-source-section';

export const useSearchTracking = () => {
    const tracker = useTracker();

    const onTileClick = useCallback(
        (
            targetRef: React.RefObject<HTMLElement>,
            itemID?: number,
            professionalID?: number
        ) => {
            tracker.log('SearchTileClick', {
                url: new URL(window.location.href),
                data: {
                    LibraryItemID: itemID,
                    ProfessionalID: professionalID,
                },
            });
        },
        [tracker]
    );

    const onPerformSearch = useCallback(
        (query: string) => {
            tracker.log('PerformSearch', {
                url: new URL(window.location.href),
                data: {
                    ExtraData: query,
                },
            });
        },
        [tracker]
    );

    const onSearchSuggestionClick = useCallback(
        (query: string) => {
            tracker.log('SearchSuggestionClick', {
                url: new URL(window.location.href),
                data: {
                    ExtraData: query,
                },
            });
        },
        [tracker]
    );

    const onRecentSearchClick = useCallback(
        (query: string) => {
            tracker.log('RecentSearchClick', {
                url: new URL(window.location.href),
                data: {
                    ExtraData: query,
                },
            });
        },
        [tracker]
    );

    const onSuggestedCategoryClick = useCallback(
        (title: string, link: string) => {
            tracker.log('SearchSuggestedCategoryClick', {
                url: new URL(window.location.href),
                data: {
                    ExtraData: JSON.stringify({ title, link }),
                },
            });
        },
        [tracker]
    );

    const onTileMediaSliderSlideChange = useCallback(
        (direction: 'CarouselPrev' | 'CarouselNext', itemID?: number) => {
            const sectionSource = getSourceSection();
            tracker.log(direction, {
                url: new URL(window.location.href),
                data: {
                    ExtraData: {
                        source: sectionSource,
                        LibraryItemID: itemID,
                    },
                },
            });
        },
        [tracker]
    );

    // add tracking to rocco tiles
    useSharedTiles({
        trackingConfig: {
            onClick: onTileClick,
            onTileMediaSliderSlideChange,
        },
    });

    return {
        onPerformSearch,
        onSearchSuggestionClick,
        onRecentSearchClick,
        onSuggestedCategoryClick,
    };
};
