import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import type { SearchIndexes } from '@rocco/types/enum/search-indexes';
import type { SearchQuery } from '@rocco/ui/search/view-models/search-query';
import { useSearchTracking } from '@archipro-website/web/app/ui-rocco/hooks/use-search-tracking';

interface AutoSubmitConfig {
    minLength?: number;
    delay?: number;
}

interface UseSearchInputProps {
    query: SearchQuery;
    doSearch: (query: string, searchIndex?: SearchIndexes) => void;
    disableSuggestions?: boolean;
    searchIndex?: SearchIndexes;
    autoSubmit?: boolean | AutoSubmitConfig;
}

const MIN_QUERY_LENGTH = 2;

export function useSearchInput({
    query,
    doSearch,
    disableSuggestions = false,
    searchIndex,
    autoSubmit = false,
}: UseSearchInputProps) {
    const {
        searchQuery,
        trimmedQuery,
        updateQuery,
        suggestionGroups,
        clearSuggestions,
        fetchSuggestions,
    } = query;

    const lastSearchedRef = useRef<string>('');
    const trackingConfig = useSearchTracking();
    const handleSearch = useCallback(
        (newQuery?: string) => {
            doSearch(newQuery || trimmedQuery, searchIndex);
 

            if (newQuery) {
                updateQuery(newQuery);
                trackingConfig.onPerformSearch(newQuery);
            } else {
                trackingConfig.onPerformSearch(trimmedQuery);
            }

            clearSuggestions();
        },
        [doSearch, trimmedQuery, updateQuery, clearSuggestions, searchIndex],
    );

    // Memoize config
    const config = useMemo(
        () => ({
            enabled: Boolean(autoSubmit),
            minLength:
                typeof autoSubmit === 'object' ? autoSubmit.minLength ?? 2 : 2,
            delay:
                typeof autoSubmit === 'object' ? autoSubmit.delay ?? 500 : 500,
        }),
        [autoSubmit],
    );

    // Auto-submit effect
    useEffect(() => {
        if (!config.enabled || !searchQuery) return;

        const trimmedQuery = searchQuery.trim();
        if (trimmedQuery.length < config.minLength) return;

        if (trimmedQuery === lastSearchedRef.current) return;

        const timer = setTimeout(() => {
            lastSearchedRef.current = trimmedQuery;
            doSearch(trimmedQuery, searchIndex);
        }, config.delay);

        return () => clearTimeout(timer);
    }, [searchQuery, config, doSearch, searchIndex]);

    const handleInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newQuery = event.target.value;
            updateQuery(newQuery);

            if (
                !disableSuggestions &&
                newQuery.trim().length >= MIN_QUERY_LENGTH
            ) {
                fetchSuggestions(newQuery);
            } else if (searchQuery !== newQuery) {
                clearSuggestions();
            }
        },
        [
            updateQuery,
            disableSuggestions,
            searchQuery,
            fetchSuggestions,
            clearSuggestions,
        ],
    );

    const handleKeyPress = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleSearch();
                // the focus will scroll down the page to the search island at bottom of page on IOS
                // so we need to blur the input to remove the focus
                event.currentTarget.blur();
            }
        },
        [handleSearch],
    );

    const clearSearchQuery = useCallback(() => {
        updateQuery('');
        clearSuggestions();
    }, [updateQuery, clearSuggestions]);

    return {
        searchQuery,
        handleInputChange,
        handleKeyPress,
        handleSearch,
        suggestionGroups,
        clearSearchQuery,
    };
}
