import React from 'react';
import type {
    SearchSuggestion,
    SearchSuggestionGroup,
} from '@rocco/ui/search/view-models/search-suggestion';
import { SearchSuggestionList } from '@rocco/ui/search/components/SearchSuggestionList';

interface SearchSuggestionPopupProps {
    suggestionGroups: SearchSuggestionGroup[];
    query: string;
    onSearchSuggestionClick: (suggestion: SearchSuggestion | string) => void;
}

export const SearchSuggestionPopup: React.FC<SearchSuggestionPopupProps> = ({
    suggestionGroups,
    query,
    onSearchSuggestionClick,
}) => {
    const filteredSuggestionGroups = suggestionGroups.filter(
        group => group.options.length > 0,
    );

    if (filteredSuggestionGroups.length === 0) {
        return <></>;
    }

    return (
        <div
            className="absolute top-full z-popover mt-4 w-full rounded-xl border border-gray-300 bg-white p-8 pt-9 shadow-lg"
            id="search-autocomplete"
            aria-haspopup="listbox"
        >
            <div className="flex flex-col gap-6">
                {filteredSuggestionGroups.map((group, groupIndex) => (
                    <SearchSuggestionList
                        key={groupIndex}
                        group={group}
                        query={query}
                        onOptionClick={suggestion =>
                            onSearchSuggestionClick(suggestion)
                        }
                    />
                ))}
            </div>
        </div>
    );
};
