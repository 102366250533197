import type {
    URLInfo,
    URLParamValue,
    URLParamValues,
} from '@modules-rocco/url/types';
import { parseURL } from '@modules-rocco/url/api/parse-url';
import {
    isCustomPathUrlParamConfiguration,
    isPathUrlParamConfiguration,
    resolveDynamicBoolean,
} from '../config/url.config';
import type { PathUrlParamConfiguration } from '@modules-rocco/url/config/url.config';
import {
    urlConfig,
    type UrlConfiguration,
    type UrlParamConfiguration,
} from '@modules-rocco/url/config/url.config';
import type { FeatureFlags } from '@modules/root';

interface PathParameter {
    name: string;
    pathElement: string;
}

interface PathGenerationResult {
    path: string;
    usedKeys: Set<string>;
}

/**
 * Generates a URL by combining a base path with path parameters and search parameters.
 *
 * This function takes a URL (either as a string or URLInfo object) and generates a new URL by:
 * 1. Preserving or overriding existing parameters based on the overrideParams flag
 * 2. Adding new parameter values
 * 3. Converting parameters to the correct type based on configuration
 * 4. Organizing path parameters according to configured groups and order
 * 5. Generating search parameters for non-path parameters
 *
 * @param url - The original URL as a string or URLInfo object. Can include both path and query parameters.
 * @param newValues - New parameter values to add or update in the URL. Values can be strings, numbers, booleans or arrays.
 * @param overrideParams - If true, completely replaces existing parameters with newValues. If false, merges with existing parameters.
 * @param featureSet - The set of features to include in the generation process.
 * @param config - Configuration object defining parameter types and path ordering. Defaults to urlConfig.
 * @returns A string containing the generated URL with all parameters properly formatted
 */
export const generateURL = (
    url: string | URLInfo,
    newValues?: URLParamValues,
    overrideParams = false,
    featureSet: FeatureFlags = [],
    config: UrlConfiguration = urlConfig
): string => {
    const { basePath, params } = typeof url === 'string' ? parseURL(url) : url;

    const originalValues = Object.fromEntries(
        Object.entries(params).map(([key, param]) => [key, param.value])
    );

    const resultParams = overrideParams
        ? newValues ?? {}
        : { ...originalValues, ...newValues };

    // we need to cast types for the resultParams values + filter out `undefined` values
    const resultParamsTyped: URLParamValues = Object.fromEntries(
        Object.entries(resultParams)
            .map(([key, value]) => [key, castValue(value, config.params[key])])
            .filter(([, param]) => param !== undefined)
    );

    // collect path params from config
    const pathParamConfigs = Object.fromEntries(
        Object.entries(config.params).filter(
            ([, urlParamConfig]) =>
                isPathUrlParamConfiguration(urlParamConfig) &&
                resolveDynamicBoolean(urlParamConfig.asPath, featureSet)
        )
    ) as Record<string, PathUrlParamConfiguration>;

    const { path: pathParameters, usedKeys } = generatePathParameters(
        resultParamsTyped,
        pathParamConfigs,
        config.pathParametersOrder
    );

    // Create remaining params object without the used path parameters
    const remainingParams = Object.fromEntries(
        Object.entries(resultParamsTyped).filter(([key]) => !usedKeys.has(key))
    );

    const searchParams = generateSearchParameters(remainingParams);

    const path = basePath + pathParameters;

    return searchParams ? `${path}?${searchParams}` : path;
};

const castValue = (
    value: URLParamValue,
    urlParamConfig?: UrlParamConfiguration
) => {
    if (urlParamConfig) {
        if (urlParamConfig.type === 'boolean') {
            return castBoolean(value);
        }

        if (
            urlParamConfig.type === 'string' ||
            urlParamConfig.type === 'searchable'
        ) {
            return urlParamConfig.multiple
                ? castStringArray(value)
                : castString(value);
        }

        if (urlParamConfig.type === 'number') {
            return castNumber(value);
        }

        if (urlParamConfig.type === 'range') {
            return castNumberRange(value);
        }
    }

    // TODO: add logging here (it means we don't have any configuration for the parameter)

    return value;
};

const generatePathParameters = (
    params: URLParamValues,
    paramConfigs: Record<string, PathUrlParamConfiguration>,
    order: Record<string, number>
): PathGenerationResult => {
    if (!params) {
        return { path: '', usedKeys: new Set() };
    }

    const pathParameters: PathParameter[] = [];
    const matchGroups: Record<string, string[]> = {};
    const keyNames = Object.keys(paramConfigs);
    const usedKeys = new Set<string>();

    // collect matching path parameters
    Object.entries(params).forEach(([key, value]) => {
        if (!value || !keyNames.includes(key)) return;

        // multiple values, fallback to search parameters!
        if (Array.isArray(value) && (value.length > 1 || !value.length)) return;

        const group = paramConfigs[key]?.group ?? '__default__';
        const currentGroupValues = matchGroups[group] ?? [];
        matchGroups[group] = [...currentGroupValues, key];
    });

    // check groups for duplicates (filter out groups with keys count < 2 and join the rest keys in a single array)
    // only one key is allowed as a path param in a group
    // if there are duplicates, we need to filter out the group entirely
    const keysToProcess = Object.entries(matchGroups)
        .filter(
            ([groupName, group]) =>
                groupName === '__default__' || group.length < 2
        )
        .flatMap(([, group]) => group);

    keysToProcess.forEach((key) => {
        if (params[key]) {
            const paramConfig = paramConfigs[key];
            const singleValue = params[key];

            // add key to used keys
            // we need to know which params are used in the path to exclude them from the search params
            usedKeys.add(key);

            const value = Array.isArray(singleValue)
                ? singleValue[0]
                : singleValue;

            // generate path element
            // if the param is a custom path param, use the custom path, otherwise use the default path element
            // key and value are separated by the separator from the config with a '-' as fallback
            const separator = paramConfig?.separator ?? '-';

            if (separator.includes('/')) {
                throw new Error('Separator cannot contain forward slashes');
            }

            const defaultPathElement = `${key}${separator}${value}`;
            const pathElement = isCustomPathUrlParamConfiguration(paramConfig)
                ? paramConfig.customPath ?? defaultPathElement
                : defaultPathElement;

            pathParameters.push({
                name: key,
                pathElement,
            });
        }
    });

    // sort path parameters by order given in the config
    const path = pathParameters
        .filter((p) => !!p.pathElement)
        .sort((a, b) => (order[a.name] ?? 0) - (order[b.name] ?? 0))
        .map((p) => p.pathElement)
        .join('/');

    return {
        path: path.length > 0 ? `/${path}` : '',
        usedKeys,
    };
};

const generateSearchParameters = (
    params: URLParamValues
): string | undefined => {
    if (!params) {
        return undefined;
    }

    const newSearchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, values]) => {
        if (Array.isArray(values)) {
            values
                .sort()
                .forEach((value) =>
                    newSearchParams.append(key, value.toString())
                );
        } else if (values) {
            newSearchParams.set(key, values.toString());
        }
    });

    newSearchParams.sort();

    return newSearchParams.toString();
};

export const castBoolean = (value: URLParamValue): string | undefined => {
    const singleValue = Array.isArray(value) ? value[0] ?? 0 : value;

    return singleValue === '1' ||
        singleValue === 1 ||
        singleValue === true ||
        singleValue === 'true'
        ? '1'
        : '0';
};

export const castString = (value: URLParamValue): string | undefined => {
    if (Array.isArray(value)) {
        return value[0]?.toString();
    } else {
        return value.toString();
    }
};

export const castNumber = (value: URLParamValue): string | undefined => {
    const singleValue = Array.isArray(value) ? value[0] : value;

    if (
        typeof singleValue === 'boolean' ||
        singleValue?.toString().length === 0
    ) {
        return undefined;
    }

    const numberValue = Number(singleValue);

    if (isNaN(numberValue)) {
        return undefined;
    }

    return numberValue.toString();
};

export const castStringArray = (value: URLParamValue): string[] | undefined => {
    if (Array.isArray(value) && !value.length) {
        return undefined;
    }

    if (Array.isArray(value)) {
        return value.map((v) => v.toString());
    } else {
        return [value.toString()];
    }
};

export const castNumberRange = (
    value: URLParamValue,
    multiplier = 1
): string | undefined => {
    const isArray = Array.isArray(value);

    if (isArray && value.length < 2) {
        return undefined;
    }

    const singleValue = isArray ? [value[0], value[1]] : value;

    if (typeof singleValue === 'boolean' || typeof singleValue === 'number') {
        return undefined;
    }

    const rawRange =
        typeof singleValue === 'string' ? singleValue.split(',') : singleValue;

    const range = rawRange.map(
        (value) =>
            (typeof value !== 'number'
                ? parseFloat(value?.toString() ?? '0')
                : value) * multiplier
    );

    if (range.length < 2 || range.some((v) => isNaN(v))) {
        return undefined;
    }

    return range
        .slice(0, 2)
        .sort((a, b) => a - b)
        .join(',');
};
