import { useLocalStorageState } from 'ahooks';
import { useHydrated } from 'remix-utils';

interface RecentlySearched {
    recentlySearched: string[];
    addRecentSearch: (x: string) => void;
    clearHistory: () => void;
}

interface UseRecentlySearched {
    (): RecentlySearched;
}

const MAX_LOCAL_SEARCH_STORAGE = 5;

export const useRecentlySearched: UseRecentlySearched = () => {
    const hydrated = useHydrated();
    const [recentlySearched, setRecentlySearched] = useLocalStorageState<
        string[]
    >('recently-searched', {
        defaultValue: [],
    });

    const addRecentSearch = (search: string) => {
        if (!search) return;
        if (recentlySearched.includes(search)) return; // Prevent adding duplicates
        const searches = [search, ...(recentlySearched ?? [])];
        const result = searches.slice(0, MAX_LOCAL_SEARCH_STORAGE);
        setRecentlySearched(result);
    };

    const clearHistory = () => {
        setRecentlySearched([]);
    };

    return {
        recentlySearched: hydrated ? recentlySearched : [],
        addRecentSearch,
        clearHistory,
    };
};
