import { hasFeature, type FeatureFlags } from '~/modules/root';

export type UrlParamConfiguration =
    | BaseUrlParamConfiguration
    | PathUrlParamConfiguration
    | CustomPathUrlParamConfiguration;

type UrlParamType =
    | 'boolean'
    | 'string'
    | 'number'
    | 'searchable'
    | 'range'
    | 'location';

/**
 * Configuration for a feature-flagged boolean value.
 * Allows a boolean property to be dynamically determined based on a feature flag.
 *
 * @property value - The static boolean value to use when feature flag is not present
 * @property featureFlag - The name of the feature flag that controls this value
 * @property whenEnabled - The value to use when the feature flag is enabled
 */
interface FeatureFlaggedBoolean {
    value: boolean;
    featureFlag: string;
    whenEnabled: boolean;
}

/**
 * Type representing either a direct boolean value or a feature-flagged boolean configuration
 */
type DynamicBoolean = boolean | FeatureFlaggedBoolean;

/**
 * Base configuration interface for URL parameters.
 * This interface defines the common properties that all URL parameter configurations must have.
 *
 * @property type - The data type of the parameter. Can be:
 *                  - 'boolean' for true/false values
 *                  - 'string' for text values
 *                  - 'number' for numeric values
 *                  - 'searchable' for searchable text values
 *                  - 'range' for numeric ranges (e.g. price ranges)
 *                  - 'location' for location-based values
 * @property asPath - Optional. If true, the parameter will be included in the URL path rather than as a query parameter.
 *                   Can be a direct boolean or a feature-flagged configuration.
 *                   Example: /colors-blue vs ?colors=blue
 * @property encode - Optional. If true, the parameter value will be URL encoded when added to the URL.
 *                   Useful for parameters that may contain special characters.
 * @property multiple - Optional. If true, the parameter can accept multiple values.
 *                     For path parameters, only the first value will be used in the path.
 *                     For query parameters, all values will be included.
 */
interface BaseUrlParamConfiguration {
    type: UrlParamType;
    asPath?: DynamicBoolean;
    encode?: boolean;
    multiple?: boolean;
}

/**
 * Helper function to resolve a dynamic boolean value based on feature flags
 *
 * @param value - The dynamic boolean value to resolve
 * @param featureSet - The current feature flags
 * @returns The resolved boolean value
 */
export const resolveDynamicBoolean = (
    value: DynamicBoolean | undefined,
    featureSet: FeatureFlags
): boolean | undefined => {
    if (typeof value === 'undefined') {
        return undefined;
    }

    if (typeof value === 'boolean') {
        return value;
    }

    return hasFeature(featureSet, value.featureFlag)
        ? value.whenEnabled
        : value.value;
};

/**
 * Configuration interface for URL parameters that should appear in the URL path.
 * Extends BaseUrlParamConfiguration with additional properties specific to path parameters.
 * Used for parameters that should be part of the URL path rather than query string.
 *
 * @example
 * // For a configuration with asPath: true
 * // URL will be: /colors-blue?page=1
 * // Instead of: ?colors=blue&page=1
 *
 * @property asPath - Must be true or a DynamicBoolean for path parameters. Indicates this parameter appears in the URL path.
 * @property separator - Optional. The separator character to use between parameter name and value in the URL path.
 *                      Defaults to '-' if not specified.
 * @property group - Optional. Groups related path parameters together.
 *                  Only one parameter from each group will be included in the path.
 *                  Useful for mutually exclusive parameters like sorting options.
 */
export interface PathUrlParamConfiguration extends BaseUrlParamConfiguration {
    asPath: DynamicBoolean | true;
    separator?: string;
    group?: string;
}

/**
 * Configuration interface for boolean path parameters with custom path names.
 * Extends PathUrlParamConfiguration to allow specifying a custom path segment.
 * Used when you want to use a different name in the URL path than the parameter name.
 *
 * @example
 * // For a configuration with customPath: 'awarded'
 * // Parameter name: 'hasAwards'
 * // URL will be: /awarded
 * // Instead of: /hasAwards-1
 *
 * @property type - Must be 'boolean'. This configuration is only for boolean parameters.
 * @property customPath - The custom path segment to use instead of the default 'paramName-value' format.
 */
interface CustomPathUrlParamConfiguration extends PathUrlParamConfiguration {
    type: 'boolean';
    customPath: string;
}

const isBaseUrlParamConfiguration = (
    config: unknown
): config is BaseUrlParamConfiguration => {
    return (
        typeof config === 'object' &&
        config !== null &&
        'type' in config &&
        typeof config.type === 'string'
    );
};

export const isPathUrlParamConfiguration = (
    config: unknown
): config is PathUrlParamConfiguration => {
    if (!isBaseUrlParamConfiguration(config) || !('asPath' in config)) {
        return false;
    }

    const asPath = config.asPath;
    return (
        asPath === true ||
        (asPath && typeof asPath === 'object' && 'value' in asPath) ||
        false
    );
};

export const isCustomPathUrlParamConfiguration = (
    config: unknown
): config is CustomPathUrlParamConfiguration => {
    return (
        isPathUrlParamConfiguration(config) &&
        config.type === 'boolean' &&
        'customPath' in config &&
        typeof config.customPath === 'string'
    );
};

/**
 * Type representing a collection of URL parameter configurations
 * Maps parameter names to their individual configurations
 */
type UrlParamsConfiguration = Record<string, UrlParamConfiguration>;

/**
 * Configuration interface for URL structure and parameter ordering
 * @property params - Collection of parameter configurations for the URL
 * @property pathParametersOrder - Defines the order of parameters that appear in the URL path
 *                                Maps parameter names to their position in the path
 */
export interface UrlConfiguration {
    params: UrlParamsConfiguration;
    pathParametersOrder: Record<string, number>;
}

/**
 * Configuration for URL filter parameters used across the site
 * Contains parameters for:
 * - User preferences and search
 * - Product availability filters (sale, stock, delivery, samples)
 * - Professional profile filters (products, videos, awards)
 * - Category and classification filters
 * - Physical attributes (colors, materials)
 * - Professional relationship filters (parents, business focuses)
 * - Searchable entities (professionals, ranges, associations etc)
 * - Product dimension ranges (price, length, width etc)
 * - Location based search
 */
const filtersUrlParams = {
    updateUserPrefs: {
        type: 'boolean',
    },
    search: {
        type: 'string',
    },
    category: {
        type: 'string',
        asPath: true,
        separator: '_',
    },
    categoryLink: {
        type: 'string',
    },
    forSale: {
        type: 'boolean',
    },
    isOnSale: {
        type: 'boolean',
    },
    isInStock: {
        type: 'boolean',
    },
    isDeliveryAvailable: {
        type: 'boolean',
    },
    isSampleAvailable: {
        type: 'boolean',
    },
    hasProducts: {
        type: 'boolean',
    },
    hasVideos: {
        type: 'boolean',
    },
    hasAwards: {
        type: 'boolean',
    },
    projectOfTheMonth: {
        type: 'boolean',
    },
    invalidLink: {
        type: 'boolean',
    },
    profileOnly: {
        type: 'boolean',
    },
    noCategory: {
        type: 'boolean',
    },
    colors: {
        type: 'string',
        asPath: {
            featureFlag: 'seo_indexable_filter_colour',
            value: false,
            whenEnabled: true,
        },
        multiple: true,
        group: 'indexable-filters',
    },
    professionalParents: {
        type: 'string',
        multiple: true,
    },
    businessFocuses: {
        type: 'string',
        multiple: true,
    },
    projectCount: {
        type: 'number',
        multiple: true,
    },
    materials: {
        type: 'searchable',
        asPath: {
            featureFlag: 'seo_indexable_filter_material',
            value: false,
            whenEnabled: true,
        },
        multiple: true,
        encode: true,
        group: 'indexable-filters',
    },
    professionals: {
        type: 'searchable',
        multiple: true,
    },
    libraryRanges: {
        type: 'searchable',
        multiple: true,
    },
    associations: {
        type: 'searchable',
        multiple: true,
    },
    professions: {
        type: 'searchable',
        multiple: true,
    },
    brands: {
        type: 'searchable',
        multiple: true,
    },
    designers: {
        type: 'searchable',
        multiple: true,
    },
    professionalCategories: {
        type: 'searchable',
        multiple: true,
    },
    downloads: {
        type: 'searchable',
        multiple: true,
    },
    priceRange: {
        type: 'range',
    },
    lengthRange: {
        type: 'range',
    },
    widthRange: {
        type: 'range',
    },
    heightRange: {
        type: 'range',
    },
    diameterRange: {
        type: 'range',
    },
    weightRange: {
        type: 'range',
    },
    city: {
        type: 'string',
    },
    country: {
        type: 'string',
    },
    region: {
        type: 'string',
    },
    suburb: {
        type: 'string',
    },
    district: {
        type: 'string',
    },
    'location-state': {
        type: 'string',
    },
} satisfies UrlParamsConfiguration;

/**
 * Configuration for URL sorting parameters
 * Defines how results can be sorted in listings
 * Sort parameter appears in the URL path rather than as a query parameter
 */
const sortUrlParams = {
    sort: {
        type: 'string',
        asPath: true,
    },
} satisfies UrlParamsConfiguration;

/**
 * Configuration for URL pagination parameters
 * Controls the current page number and number of items per page
 * Used for paginated listings across the site
 */
const paginationUrlParams = {
    page: {
        type: 'number',
    },
    pageSize: {
        type: 'number',
    },
} satisfies UrlParamsConfiguration;

/**
 * Main URL configuration object that combines all parameter configurations
 * Includes:
 * - All filter parameters for searching and filtering content
 * - Sort parameters for ordering results
 * - Pagination parameters for managing result sets
 * - Path parameter order defining the sequence of parameters in URL paths
 */
export const urlConfig = {
    params: {
        ...filtersUrlParams,
        ...sortUrlParams,
        ...paginationUrlParams,
    },
    pathParametersOrder: {
        company: 0,
        category: 1,
        profession: 2,
        colors: 3,
        materials: 4,
        location: 5,
        sort: 6,
    },
} satisfies UrlConfiguration;

/**
 * Type utility to convert URL parameter configuration type to its corresponding TypeScript type
 */
export type UrlConfigParamTypeToTS<T extends UrlParamConfiguration> =
    T['type'] extends 'boolean'
        ? boolean
        : T['type'] extends 'string'
          ? T['multiple'] extends true
              ? string[]
              : string
          : T['type'] extends 'number'
            ? T['multiple'] extends true
                ? number[]
                : number
            : T['type'] extends 'searchable'
              ? T['multiple'] extends true
                  ? string[]
                  : string
              : T['type'] extends 'range'
                ? number[]
                : T['type'] extends 'location'
                  ? string
                  : never;
