import React, { useRef, useEffect, useState } from 'react';
import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';
import { ReactComponent as SearchIcon } from '@rocco/icons/svg/search.svg';
import { ReactComponent as CloseIcon } from '@rocco/icons/svg/close.svg';
import { Button as RoccoButton } from '@rocco/components/button';
import { useClickAway, useInViewport } from 'ahooks';
import type {
    SearchSuggestion,
    SearchSuggestionGroup,
} from '@rocco/ui/search/view-models/search-suggestion';
import { SearchSuggestionPopup } from '@rocco/ui/search/components/SearchSuggestionPopup';
import { DisplayMode } from '@rocco/types/enum/display-mode';
import { SearchRecentlySearchedPopup } from './SearchRecentlySearchedPopup';
import { uiStateAtomSearchClicked } from '@rocco/ui/website-header/states/ui-states-search-clicked';
import { useAtom } from 'jotai';

export interface SearchBarProps
    extends VariantProps<typeof _searchBarVariants> {
    placeholderTexts: string[];
    rotationInterval?: number;
    suggestionGroups?: SearchSuggestionGroup[];
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onSearchSubmit: (
        value: string | SearchSuggestion,
        isCategory: boolean,
    ) => void;
    onSearchSuggestionClick?: (suggestion: SearchSuggestion | string) => void;
    clearSearchQuery: () => void;
    hideClearButton?: boolean;
    hideSubmitButton?: boolean;
    displayMode: DisplayMode;
    disableDropdown?: boolean;
    recentlySearched?: string[];
    clearHistory?: () => void;
    onRecentlySearchedClick?: (search: string) => void;
}

const _searchBarVariants = cva(
    'relative flex items-center justify-between overflow-hidden rounded-full',
    {
        variants: {
            variant: {
                transparent: 'bg-transparent',
                searchPageMobile:
                    'p-5 h-[3.625rem] border-[1px] border-[#F2f2f2] bg-[#F7f7f7]',
                default:
                    'bg-white shadow-[inset_0px_0px_3px_0px_rgba(0,0,0,0.25)] md:p-2',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

const _searchBarInputVariants = cva(
    'w-full bg-transparent [&::-webkit-search-cancel-button]:hidden placeholder:text-ellipsis text-input-size placeholder:text-gray-700',
    {
        variants: {
            variant: {
                transparent: 'pl-6 md:pl-4 outline-none',
                searchPageMobile: 'text-sm outline-none',
                default:
                    'pl-6 pr-0 md:pl-4 md:pr-4 py-3 text-gray-700 outline-none',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

export const SearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(
    (
        {
            placeholderTexts,
            rotationInterval = 3000,
            suggestionGroups,
            value,
            onChange,
            onKeyDown,
            onSearchSubmit,
            clearSearchQuery,
            variant,
            hideClearButton = false,
            hideSubmitButton = false,
            disableDropdown = false,
            displayMode,
            onSearchSuggestionClick,
            recentlySearched,
            clearHistory,
            onRecentlySearchedClick,
        },
        forwardedRef,
    ) => {
        const [currentPlaceholderIndex, setCurrentPlaceholderIndex] =
            useState(0);
        const [shouldShowDropdown, setShouldShowDropdown] = useState(false);
        const containerRef = useRef<HTMLDivElement>(null);
        const [isVisible] = useInViewport(containerRef);
        const [isFocused, setIsFocused] = useState(false);

        // this state is used to fight the race condition between the input blur and the dropdown visibility
        // we need to pause the blur event for 200ms to make sure the dropdown is visible and the click event is fired
        const [shouldBlur, setShouldBlur] = useState(false);
        const blurTimeoutRef = useRef<NodeJS.Timeout>();

        const hasValue = value.length > 0;
        const filteredSuggestionGroups =
            suggestionGroups?.filter(group => group.options.length > 0) ?? [];

        const hasSuggestions = filteredSuggestionGroups.length > 0;

        const localInputRef = useRef<HTMLInputElement>(null);
        const inputRef = (forwardedRef ||
            localInputRef) as React.RefObject<HTMLInputElement>;

        const [searchIconClicked] = useAtom(uiStateAtomSearchClicked);

        useEffect(() => {
            if (value.length === 0 && isVisible) {
                const interval = setInterval(() => {
                    setCurrentPlaceholderIndex(
                        prevIndex => (prevIndex + 1) % placeholderTexts.length,
                    );
                }, rotationInterval);

                return () => clearInterval(interval);
            }
        }, [
            placeholderTexts.length,
            rotationInterval,
            value.length,
            isVisible,
        ]);

        useClickAway(() => {
            setShouldShowDropdown(false);
        }, containerRef);

        const handleInputFocus = () => {
            setIsFocused(true);
            setShouldBlur(false);
            if (blurTimeoutRef.current) {
                clearTimeout(blurTimeoutRef.current);
            }

            // this is to prevent the dropdown from showing when we first landed on the search page
            // but we still want to retain the auto focus on the input
            if (!searchIconClicked) {
                setShouldShowDropdown(true);
            }
        };

        const handleInputBlur = () => {
            setShouldBlur(true);
            blurTimeoutRef.current = setTimeout(() => {
                if (shouldBlur) {
                    setIsFocused(false);
                }
            }, 200);
        };

        useEffect(() => {
            return () => {
                if (blurTimeoutRef.current) {
                    clearTimeout(blurTimeoutRef.current);
                }
            };
        }, []);

        const handleSubmit = (e: React.FormEvent) => {
            e.preventDefault();
            onSearchSubmit(value, false);
        };

        const handleClearQuery = () => {
            clearSearchQuery();
        };

        const canShowSuggestion =
            hasValue && hasSuggestions && onSearchSuggestionClick && isFocused;
        const canShowRecentlySearched =
            !hasValue &&
            recentlySearched &&
            recentlySearched.length > 0 &&
            clearHistory &&
            onRecentlySearchedClick &&
            isFocused;
        const popupMode = canShowSuggestion
            ? 'suggestion'
            : canShowRecentlySearched
              ? 'recentlySearched'
              : 'none';

        return (
            <div
                ref={containerRef}
                className="relative mx-auto w-full bg-transparent"
                onClick={() => {
                    inputRef.current?.focus();
                }}
            >
                <form onSubmit={handleSubmit} role="search">
                    <div className={_searchBarVariants({ variant })}>
                        {variant === 'searchPageMobile' && (
                            <div className="mr-2.5">
                                <SearchIcon className="size-4 stroke-gray-700" />
                            </div>
                        )}
                        <input
                            ref={inputRef}
                            type="search"
                            enterKeyHint="search"
                            value={value}
                            onChange={onChange}
                            onKeyDown={e => {
                                setShouldShowDropdown(true);
                                onKeyDown?.(e);
                            }}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            placeholder={
                                placeholderTexts[currentPlaceholderIndex]
                            }
                            className={_searchBarInputVariants({ variant })}
                            aria-autocomplete="list"
                            autoComplete={'off'}
                            aria-controls="search-autocomplete"
                            name="search"
                            tabIndex={0}
                        />

                        <div className="flex items-center gap-x-2">
                            {!hideClearButton && hasValue ? (
                                <RoccoButton
                                    type="button"
                                    color="secondary"
                                    size={
                                        displayMode === 'mobile'
                                            ? 'square50'
                                            : 'square72'
                                    }
                                    onClick={handleClearQuery}
                                    aria-label="Clear Search Query"
                                    {...(variant === 'searchPageMobile' && {
                                        size: 'square18',
                                        color: 'transparent',
                                    })}
                                >
                                    <CloseIcon className="size-5" />
                                </RoccoButton>
                            ) : null}

                            {!hideSubmitButton && (
                                <RoccoButton
                                    type="submit"
                                    color={'dark'}
                                    size={
                                        displayMode === 'mobile'
                                            ? 'square50'
                                            : 'square72'
                                    }
                                    aria-label="Submit Search"
                                    style={{
                                        opacity:
                                            variant === 'transparent' &&
                                            displayMode === 'desktop' &&
                                            !isFocused
                                                ? 0.8
                                                : 1,
                                    }}
                                >
                                    <SearchIcon className="size-5 stroke-white" />
                                </RoccoButton>
                            )}
                        </div>
                    </div>
                </form>

                {!disableDropdown && shouldShowDropdown && (
                    <>
                        {popupMode === 'suggestion' ? (
                            <SearchSuggestionPopup
                                suggestionGroups={filteredSuggestionGroups}
                                query={value}
                                onSearchSuggestionClick={
                                    onSearchSuggestionClick ?? (() => {})
                                }
                            />
                        ) : popupMode === 'recentlySearched' ? (
                            <SearchRecentlySearchedPopup
                                recentlySearched={recentlySearched ?? []}
                                clearHistory={clearHistory ?? (() => {})}
                                onItemClick={
                                    onRecentlySearchedClick ?? (() => {})
                                }
                            />
                        ) : null}
                    </>
                )}
            </div>
        );
    },
);

SearchBar.displayName = 'SearchBar';
