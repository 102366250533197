import { useState, useEffect, useCallback, useMemo } from 'react';
import { useFetcher } from '@remix-run/react';
import type { SearchSuggestionGroup } from '@rocco/ui/search/view-models/search-suggestion';
import type { SearchSuggestionResultGroup } from '@modules-rocco/search/types/api';

/**
 * This hook handles the local state of the search query and fetch search suggestions as the user types.
 * It initializes the search query with the provided initialQuery and updates it as the user types.
 * When the query length reaches 3 or more characters, it triggers a fetch for search suggestions.
 * The fetched suggestions are categorized into keyword and category groups.
 * The suggestion groups are stored in the state and can be used to display suggestions in the UI.
 *
 * @param initialQuery - The initial search query to set.
 * @returns An object containing the search query, a function to update the query, the suggestion groups, and a function to clear suggestions.
 */
export function useSearchQuery(initialQuery: string) {
    const [searchQuery, setSearchQuery] = useState(initialQuery);
    const suggestedCategoriesFetcher =
        useFetcher<SearchSuggestionResultGroup | null>();
    const [suggestionGroups, setSuggestionGroups] = useState<
        SearchSuggestionGroup[]
    >([]);

    const trimmedQuery = useMemo(() => searchQuery.trim(), [searchQuery]);

    const updateQuery = useCallback((newQuery: string) => {
        setSearchQuery(newQuery);
    }, []);

    const fetchSuggestions = useCallback(
        (query: string) => {
            const params = new URLSearchParams({ search: query.trim() });
            suggestedCategoriesFetcher.load(
                `/actions/search/search-suggestions?${params.toString()}`
            );
        },
        [suggestedCategoriesFetcher]
    );

    useEffect(() => {
        const suggestedKeywords: SearchSuggestionGroup = {
            options: suggestedCategoriesFetcher.data?.keywords || [],
            type: 'keyword',
        };

        const suggestedCategories: SearchSuggestionGroup = {
            options: suggestedCategoriesFetcher.data?.categories || [],
            type: 'categories',
        };

        const groups: SearchSuggestionGroup[] = [
            suggestedKeywords,
            suggestedCategories,
        ];

        setSuggestionGroups(groups);
    }, [suggestedCategoriesFetcher.data]);

    useEffect(() => {
        setSearchQuery(initialQuery);
    }, [initialQuery]);

    return {
        searchQuery,
        trimmedQuery,
        updateQuery,
        fetchSuggestions,
        suggestionGroups,
        clearSuggestions: useCallback(() => setSuggestionGroups([]), []),
    };
}
