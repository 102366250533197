import React, { useCallback } from 'react';

export function useAccessibleClick(onClick: () => void) {
    const handleInteraction = useCallback(
        (event: React.MouseEvent | React.KeyboardEvent) => {
            if (
                event.type === 'click' ||
                (event as React.KeyboardEvent).key === 'Enter'
            ) {
                onClick();
            }
        },
        [onClick],
    );

    return {
        onClick: handleInteraction,
        onKeyDown: handleInteraction,
    };
}
