import React from 'react';
import { useAccessibleClick } from '@rocco/hooks/use-accessible-click';

interface SearchSuggestionItemProps {
    label: string;
    type?: string;
    query: string;
    onClick: () => void;
}

export const SearchSuggestionItem: React.FC<
    SearchSuggestionItemProps
> = props => {
    const { onClick, label, type, query } = props;
    const { onClick: handleClick, onKeyDown } = useAccessibleClick(onClick);

    return (
        <div
            onClick={handleClick}
            onKeyDown={onKeyDown}
            className="flex cursor-pointer items-center md:p-2 md:hover:bg-gray-100 md:focus:bg-gray-100 focus:outline-none"
            role="option"
            tabIndex={0}
            aria-selected={false}
        >
            <div className="min-h-4.5 leading-[1.1] md:min-h-0 text-[#9b9b9b] md:text-gray-400 flex items-center whitespace-nowrap max-w-full gap-1">
                <span className="truncate flex-1">
                    {highlightMatch(label, query)}
                </span>
                <span className="text-gray-900 font-medium">
                    {type ? ` in ${type}` : ''}
                </span>
            </div>
        </div>
    );
};

const highlightMatch = (text: string, query: string) => {
    const lowerText = text.toLowerCase();
    const lowerQuery = query.toLowerCase();
    const index = lowerText.indexOf(lowerQuery);

    if (index !== -1) {
        const before = text.slice(0, index);
        const match = text.slice(index, index + query.length);
        const after = text.slice(index + query.length);
        return (
            <>
                <span className="text-gray-400">{before}</span>
                <span className="text-gray-800">{match}</span>
                <span className="text-gray-400">{after}</span>
            </>
        );
    }
    return <span className="text-gray-400">{text}</span>;
};
